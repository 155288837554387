import { FC } from "react";

import { Box, Stack, useTheme } from "@mui/material";

import { BaseFormV2Step1LayoutProps } from "./BaseFormV2Step1LayoutProps";

import { FormV2ZeffyLogo } from "@/features/FormV2/sharedComponents/FormV2ZeffyLogo";
import { useHasValueChanged } from "@/hooks/useHasValueChanged";

export const ONE_COLUMN_MAX_WIDTH = 597;

type BaseFormV2Step1OneColumnLayoutProps = Pick<
  BaseFormV2Step1LayoutProps,
  "slots" | "showCheckoutFooter" | "contextDrawerHeight" | "utm"
>;

export const BaseFormV2Step1OneColumnLayout: FC<BaseFormV2Step1OneColumnLayoutProps> = ({
  slots,
  showCheckoutFooter = false,
  contextDrawerHeight,
  utm = "",
}) => {
  const theme = useTheme();
  const [animateCheckoutFooter, watchedShowCheckoutFooter] = useHasValueChanged(showCheckoutFooter);

  const renderAnimatedCheckoutFooter = () => {
    const hidden = !watchedShowCheckoutFooter && !animateCheckoutFooter;

    return (
      <Box
        data-test="form-v2-checkout-footer"
        position="fixed"
        // The sticky footer should always stick to 40px from the bottom of the screen
        bottom={0}
        left={0}
        right={0}
        marginBlockStart={{ md: hidden ? 0 : undefined }}
        padding={hidden ? 0 : 2}
        sx={{
          animation: animateCheckoutFooter
            ? watchedShowCheckoutFooter
              ? "fade-slide-in 0.3s ease-in-out both"
              : "fade-slide-out 0.3s ease-in-out both"
            : undefined,
          backgroundColor: theme.palette.surface.form.supershy,
          borderTop: `1px solid ${theme.palette.surface.form.quiet}`,
          opacity: hidden ? 0 : undefined,
          height: hidden ? 0 : undefined,
          zIndex: 1000,
          ["@keyframes fade-slide-in"]: {
            from: { translate: "0 20px", opacity: 0 },
            to: { translate: "0 0", opacity: 1 },
          },
          ["@keyframes fade-slide-out"]: {
            from: { translate: "0 0", opacity: 1 },
            to: { translate: "0 20px", opacity: 0 },
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: ONE_COLUMN_MAX_WIDTH,
            marginInline: "auto",
            paddingInline: 2,
          }}
        >
          {slots.checkoutFooter}
        </Box>
      </Box>
    );
  };

  return (
    <Stack>
      <Stack width="100%" maxWidth={ONE_COLUMN_MAX_WIDTH} marginInline="auto">
        <Stack
          gap={2}
          justifyContent={{ xs: "flex-start", md: "center" }}
          minHeight={{ md: `calc(100dvh - ${contextDrawerHeight}px)` }}
          padding={theme.spacing(4, 2, 0, 2)}
          sx={{
            boxSizing: "border-box",
          }}
        >
          {slots.info}
          {slots.divider}
          {slots.order}
        </Stack>
      </Stack>
      <Box display="flex" justifyContent="center" paddingBlock={2} zIndex={1}>
        <FormV2ZeffyLogo utm={utm} formStep={1} />
      </Box>
      {/* Keep space in the page for the sticky checkout button */}
      {watchedShowCheckoutFooter && <Box sx={{ height: 75, visibility: "hidden" }} />}
      {renderAnimatedCheckoutFooter()}
    </Stack>
  );
};
