import { FC } from "react";

import { BaseFormV2Step1EmbedLayout } from "./BaseFormV2Step1EmbedLayout";
import { BaseFormV2Step1LayoutProps } from "./BaseFormV2Step1LayoutProps";
import { BaseFormV2Step1OneColumnLayout } from "./BaseFormV2Step1OneColumnLayout";
import { BaseFormV2Step1TwoColumnLayout } from "./BaseFormV2Step1TwoColumnLayout";

export * from "./BaseFormV2Step1LayoutProps";

/**
 * This base layout handles the 1 column, 2 columns and embed layouts of all forms.
 * Not meant to be used directly, but rather extended by each form.
 */
export const BaseFormV2Step1Layout: FC<BaseFormV2Step1LayoutProps> = ({ isEmbed, isOneColumn, ...rest }) => {
  if (isEmbed) {
    return <BaseFormV2Step1EmbedLayout {...rest} />;
  }

  if (isOneColumn) {
    return <BaseFormV2Step1OneColumnLayout {...rest} />;
  }

  return <BaseFormV2Step1TwoColumnLayout {...rest} />;
};
