import { FC } from "react";

import { Grid, Stack } from "@mui/material";
import { getCurrencyFromCountry } from "@simplyk/common";

import { FormZeffyDescription } from "../../../components/FormZeffyDescription/FormZeffyDescription";
import { GenerateReceiptAlert } from "../../../components/GenerateReceiptWithZeffyAlerts/VersionControlAlert/GenerateReceiptAlert";
import { useLocaleContext } from "../../../contexts/LocaleContext";
import { FormType } from "../../../gql/gql-types";
import { usePreviewContext } from "../../LiveFormEditor/LivePreview/context/PreviewContext";
import { useFormV2Context } from "../context/FormV2Context";

export const VersionControlAlertV2: FC = () => {
  const { isPreview } = usePreviewContext();
  const { displayGenerateReceipt, eligibleAmountTotal, formCountry, type } = useFormV2Context();
  const { isoLocale } = useLocaleContext();

  if (isPreview) {
    return null;
  }

  const currency = getCurrencyFromCountry(formCountry);

  return (
    <Stack gap={1}>
      {displayGenerateReceipt && (
        <GenerateReceiptAlert eligibleAmount={eligibleAmountTotal} currency={currency} isoLocale={isoLocale} />
      )}

      <Grid item xs={12}>
        <FormZeffyDescription isTicketing={type === FormType.Ticketing} />
      </Grid>
    </Stack>
  );
};
