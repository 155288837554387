import { FC } from "react";

import { Box, useTheme } from "@mui/material";

import { BaseFormV2Step1LayoutProps } from "./BaseFormV2Step1LayoutProps";
import { ONE_COLUMN_MAX_WIDTH } from "./BaseFormV2Step1OneColumnLayout";

import { useHasValueChanged } from "@/hooks/useHasValueChanged";

type BaseFormV2Step1EmbedLayoutProps = Pick<BaseFormV2Step1LayoutProps, "slots" | "showCheckoutFooter">;

export const BaseFormV2Step1EmbedLayout: FC<BaseFormV2Step1EmbedLayoutProps> = ({
  slots,
  showCheckoutFooter = false,
}) => {
  const theme = useTheme();

  const [animateCheckoutFooter, watchedShowCheckoutFooter] = useHasValueChanged(showCheckoutFooter);

  const renderAnimatedCheckoutFooter = () => {
    const hidden = !watchedShowCheckoutFooter && !animateCheckoutFooter;

    return (
      <Box
        data-test="form-v2-checkout-footer"
        position={hidden ? "absolute" : "sticky"}
        bottom={{ xs: 32, md: 40 }}
        marginBlockStart={hidden ? 0 : 2}
        sx={{
          animation: animateCheckoutFooter
            ? watchedShowCheckoutFooter
              ? "fade-slide-in 0.3s ease-in-out both"
              : "fade-slide-out 0.3s ease-in-out both"
            : undefined,
          opacity: hidden ? 0 : undefined,
          height: hidden ? 0 : undefined,
          zIndex: 1000,
          ["@keyframes fade-slide-in"]: {
            from: { translate: "0 20px", opacity: 0 },
            to: { translate: "0 0", opacity: 1 },
          },
          ["@keyframes fade-slide-out"]: {
            from: { translate: "0 0", opacity: 1 },
            to: { translate: "0 20px", opacity: 0 },
          },
        }}
      >
        {slots.checkoutFooter}
      </Box>
    );
  };

  return (
    <Box
      width="100%"
      maxWidth={ONE_COLUMN_MAX_WIDTH}
      marginInline="auto"
      padding={{ xs: theme.spacing(4, 2), md: theme.spacing(5, 2) }}
    >
      {slots.order}
      {renderAnimatedCheckoutFooter()}
    </Box>
  );
};
